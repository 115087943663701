@tailwind base;
@tailwind components;
@tailwind utilities;

/* General */

@keyframes animateLastWord {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scale {
  0%,
  20% {
    transform: scale(1);
  }
  30%,
  50% {
    transform: scale(1.15);
  }
  60%,
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.scale-1,
.scale-2,
.scale-3,
.scale-4,
.scale-5 {
  animation: scale 2s linear infinite;
}

.scale-1 {
  animation-delay: 0s;
}

.scale-2 {
  animation-delay: 2s;
}

.scale-3 {
  animation-delay: 2s;
}

.scale-4 {
  animation-delay: 2s;
}

.scale-5 {
  animation-delay: 2s;
}

/* Front page */

.picks-text {
  font-family: 'Abril Fatface';
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.container {
  position: relative;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  height: 100%;
  z-index: 2;
}

.clothing-item {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes swing {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(2deg); }
  30% { transform: rotate(-2deg); }
  50% { transform: rotate(360deg); }
  70% { transform: rotate(-2deg); }
  90% { transform: rotate(2deg); }
  100% { transform: rotate(0deg); }
}

.swing {
  animation: swing 10s infinite;
}

/* LOGIN POPUP */

@keyframes slide-in {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.login-window {
  font: 1.2rem 'Belleza', cursive;
  font-style: normal;
  position: fixed;
  top: 100px;
  right: 200px;
  background-color: rgba(167, 141, 181, 0.936);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: slide-in 0.8s ease-out;
  z-index: 101;
}

.input-field {
  font-style: italic;
  font: 1rem 'Belleza', sans-serif;
  background-color: rgba(181, 149, 169, 0.663);
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  color: white;
}

.input-field::placeholder {
  font-style: italic;
  color: rgba(255, 255, 255, 0.805);
  text-align: center;
  opacity: 80%;
}

.btn {
  background-color: rgba(164, 133, 210, 0.855);
  font: 1rem 'Belleza';
  color: white;
  border: none;
  padding: 10px;
  margin: 10px; /* This applies a 10px margin to all sides (top, right, bottom, left) */
  border-radius: 5px;
  cursor: pointer;
}

.font-AbrilFatface {
  font-family: 'Abril Fatface', sans-serif;
}

.font-Belleza {
  font-family: 'Belleza', sans-serif;
}

/* LOGIN POPUP */